<template>
<div class="container-fluid">
    <div class="row top-bar">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">
                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">
                            <h3 class="vv mb-4">Files</h3>
                        </div>
                    </div>
                </div>
                <div class="col text-right">
                    <button class="btn btn-primary float-right d-none" data-toggle="modal" data-target="#exampleModal">
                        New Upload
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-12 bg-accent border-bottom">
            <div class="row">
                <div class="col-8">
                    <ul class="nav nav-tabs px-0 mx-0 mt-2 border-0" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" @click.prevent="filterf('audio')">Audio</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="meta-tab" data-toggle="tab" href="#customize" role="tab" @click.prevent="filterf('stems')">Stems</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="meta-tab" data-toggle="tab" href="#edit" role="tab" @click.prevent="filterf('images')">Images/Artwork</a>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <div class="input-group w-25n pr-3 pull-right float-right">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <PhMagnifyingGlass class="text-muted" />
                            </div>
                        </div>
                        <input type="text" class="form-control" v-model="search_text" placeholder="Search Files..." />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row mt-5 pt-5">
        <div class="col-12 mb-4 mt-5 pt-4" v-show="ready">
            <div class="row pt-4">
                <div class="col-lg-3 mb-4 col-md-6" v-for="x in ffiles" :key="x.id">
                    <div class="row card card-files pt-2 px-0 mx-2 bg-transparent">
                        <div class="col-12 py-2 justify-content-center text-center">
                            <img v-if="x.img_url_300" class="file-img text-center justify-content-center" :src="x.img_url_300" />
                            <span v-if="x.mp3_url || x.wav_url" class="text-center fnt-48 text-muted ph-music-note-simple">
                                <PhFileAudio />
                            </span>
                            <span v-if="x.zip_url" class="text-center fnt-48 text-muted ph-archive">
                                <PhFileZip />
                            </span>
                        </div>
                        <div class="col-12 file-meta py-2">
                            <!--div class="row">
                              <div class="col-8"></div>
                              <div class="col-2"></div>
                            </div-->
                            <span class="text-muted fnt-14" :data-tooltip="x.original_name">
                                {{ truncate(x.original_name, 15) }}
                            </span>
                            <br />
                            <span class="text-muted fnt-14 text-rightb float-rightb">{{
                  fsize(x.size)
                }}</span>

                            <a class="
                  d-noneb
                    btn
                    btn-slim
                    btn-secondary
                    text-right
                    float-right
                    ph-download-simple
                    
                    btn-x
                    
                  " :href="$baseurl +'/storage/download/?link='+(x.img_url||x.zip_url||x.wav_url)"> <PhDownloadSimple :size="18" /> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
            <span class="loading-lg text-center mx-auto"></span>
            <br />
            <br />
            <span class="text-muted pt-5 mt-5">loading...</span>
        </div>
        <div class="col-lg-12" v-show="ready && empty">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12 text-center">
                    <PhFolderSimple class="text-primary" :size="100" />
                </div>
                <div class="col-lg-12 pt-2 text-center">
                    <p>You don't have any files</p>
                    <button class="d-none btn btn-primary mx-auto" data-toggle="modal" data-target="#exampleModal">
                        <PhPlus />
                        <span>Upload File</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h5 class="modal-title text-center" id="exampleModalLabel">
                        Upload Files
                    </h5>
                    <i class="ph-x btn-close" data-dismiss="modal" aria-label="Close"></i>
                </div>
                <div class="modal-body">
                    <dashboard class="dash-b" :uppy="uppy" :plugins="['ImageEditor']" />
                </div>

                <!--div class="modal-footer pb-0 px-0 mx-0 ">
                                <div class="col-6 d-table btn-modal-left px-0 mx-0 my-0 " data-dismiss="modal">
                                    <span class="align-middle d-table-cell text-center">CANCEL</span>
                                </div>
                                <div class="col-6 d-table btn-modal-right mx-0 px-0 my-0 ">
                                    <span class="align-middle d-table-cell text-center"> <i class="ci-user"></i> SUBMIT</span>
                                </div>
                            </div-->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import "@uppy/image-editor/dist/style.min.css";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import filesize from "filesize";
import {
    Dashboard
} from "@uppy/vue";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import Uppy from "@uppy/core";

import XHRUpload from "@uppy/xhr-upload";
const ImageEditor = require("@uppy/image-editor");
import {
    PhFileAudio,
    PhFileZip,
    PhMagnifyingGlass,
    PhFolderSimple,
    PhPlus,
    PhDownloadSimple,
} from "phosphor-vue";
var _ = require("lodash");
export default {
    name: "files",
    components: {
        //FilePond: vueFilePond(FilePondPluginImagePreview),
        PhFolderSimple,
        Dashboard,
        PhFileAudio,
        PhFileZip,
        PhMagnifyingGlass,
        PhPlus,
        PhDownloadSimple,
    },
    data() {
        return {
            keys: [],
            files: [],
            search_text: "",
            filter: "audio",
            ready: false,
            empty: false,
            endpoint: "",
            restrictions: {},
            signed_url: ""
        };
    },
    created() {},
    computed: {
        uppy: function () {
            return new Uppy(this.restrictions,

                )

                .use(ImageEditor, {
                    quality: 0.8,
                })
                .use(XHRUpload, {
                    method: "put",
                    limit: 1,
                    endpoint: this.signed_url,
                })
                .on("upload-success", (file, response) => {
                    const httpBody = response.body; // extracted response data

                    this.files.push(httpBody);
                    // do something with file and response
                })
                .on('file-added',
                    (file) => {
                        console.log("this")

                        console.log(file);

                        console.log("new_signed_url")
                        var baseURI = this.$baseurl + "/storage/signed_url";
                        var config = {
                            Authorization: "Bearer " + this.$store.getters.getUser.token,
                        };
                        console.log(baseURI)

                        this.$http
                            .post(
                                baseURI, {
                                    original_name: file.name,
                                }, {
                                    headers: config,
                                }
                            ).then((res) => {
                                this.signed_url = res.data["signed_url"];
                                console.log("this.signed_url")
                            });

                        /*  */
                    });
        },

        ffiles() {
            var x = this.files;
            var f = this.filter;
            var search = this.search_text;
            if (x.length > 0) {
                this.empty = false;
            } else {
                this.empty = true;
            }
            if (f == "audio") {
                x = _.filter(x, function (o) {
                    return o.wav_url;
                });
            } else if (f == "stems") {
                x = _.filter(x, function (o) {
                    return o.zip_url;
                });
            } else if (f == "images") {
                x = _.filter(x, function (o) {
                    return o.img_url;
                });
            }
            if (search != "") {
                x = _.filter(x, function (obj) {
                    return obj.original_name.indexOf(search) !== -1;
                });
            }
            return x.sort((a, b) => a.original_name.localeCompare(b.original_name));
        },
    },
    mounted() {
        var baseURI = this.$baseurl + "/storage";

        var config = {
            Authorization: "Bearer " + this.$store.getters.getUser.token,
        };
        this.$http
            .get(baseURI, {
                headers: config,
            })
            .then((result) => {
                this.files = result.data;

                this.ready = true;
                //this.keys = Object.keys(this.res[0]);
            });
        this.restrictions = {
            restrictions: {
                maxFileSize: 2000000000,
                maxNumberOfFiles: 1,
                allowedFileTypes: [".jpg", ".jpeg", ".png", ".wav", ".mp3", ".zip", ".rar"],
            },

        };
    },

    methods: {
        new_signed_url(file) {
            console.log("new_signed_url")
            var baseURI = this.$baseurl + "/storage/signed_url";
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };

            this.$http
                .post(
                    baseURI, {
                        original_name: file.name,
                    }, {
                        headers: config,
                    }
                ).then((res) => {
                    this.signed_url = res.data["signed_url"];
                });

        },
        truncate(str, n) {
            return str.length > n ? str.substr(0, n - 1) + "..." : str;
        },
        fsize(s) {
            return filesize(s);
        },
        fileimg(mime) {
            if (mime == "pdf") {
                return "ci-file_pdf";
            } else if (mime == "zip" || mime == "rar" || mime == "tar") {
                return "ci-file_archive";
            } else {
                return "ci-file_blank_fill";
            }
        },
        filterf(fltr) {
            this.search_text = "";
            this.filter = fltr;
        },
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>

<style>
</style>
